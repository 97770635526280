<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="1000px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="10" v-if="gamme.sp == 0">
                  <v-radio-group
                    v-model="option"
                    row
                    mandatory
                    dense
                    @change="produit_change"
                  >
                    <v-radio label="Produit" value="1"></v-radio>
                    <v-radio label="Semi Fini" value="2"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="3" md="3" v-if="gamme.sp == 0">
                  <v-text-field
                    autocomplete="off"
                    ref="caption"
                    dense
                    v-model.number="editedItem.caption"
                    :readonly="readonly"
                    :label="'Caption'"
                    required
                    :rules="[(v) => !!v || 'Caption obligatoire']"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8" v-if="gamme.sp == 1">
                  <cursorselect
                    :Qsearch="products_cursor"
                    :Qresp="'products_cursor'"
                    :value="editedItem.produit_id"
                    :text_fields="['code', 'label']"
                    :variableadd="variableadd_p"
                    @change="product_change"
                    :label="'Produit'"
                    :rules="true"
                    :key="cs"
                  >
                  </cursorselect>
                </v-col>
                <v-col
                  cols="12"
                  sm="5"
                  md="5"
                  v-if="option == 1 && gamme.sp == 0"
                >
                  <v-select
                    dense
                    :items="templates.filter((elm) => elm.tobuy == 1)"
                    item-text="label"
                    item-value="id"
                    v-model="editedItem.template_id"
                    label="Modele"
                    @change="produit_change"
                    :readonly="readonly"
                    :rules="[(v) => !!v || 'Modele obligatoire']"
                  >
                  </v-select>
                </v-col>

                <v-col
                  cols="12"
                  sm="5"
                  md="5"
                  v-if="option == 2 && gamme.sp == 0"
                >
                  <v-autocomplete
                    v-model="editedItem.gamme_id"
                    :item-text="'caption'"
                    :item-value="'id'"
                    :items="gammes_list"
                    label="Nomenclature"
                    :readonly="readonly"
                    :rules="[(v) => !!v || 'Nomenclature obligatoire']"
                    @change="produit_change"
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    autocomplete="off"
                    ref="dechet"
                    dense
                    v-model.number="editedItem.dechet"
                    :readonly="readonly"
                    :label="'Dechet (%)'"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  v-if="option == 1 && gamme.sp == 0"
                >
                  <v-checkbox
                    label="Optionnel"
                    v-model="editedItem.opt"
                    dense
                  ></v-checkbox>
                </v-col>

                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  v-if="option == 1 && gamme.sp == 0"
                >
                  <v-checkbox
                    label="produit Multiple"
                    v-model="editedItem.multiple"
                    dense
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save" v-if="!readonly">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_GAMME_BOM from "../graphql/Production/CREATE_GAMME_BOM.gql";
import UPDATE_GAMME_BOM from "../graphql/Production/UPDATE_GAMME_BOM.gql";
import PRODUCTS_CURSOR from "../graphql/Commande/PRODUCTS_CURSOR.gql";

export default {
  components: {
    cursorselect: () => import("./CursorSelect.vue"),
  },
  name: "bomform",
  props: {
    item: Object,
    items: Array,
    readonly: Boolean,
    showForm: Boolean,
    gammes: Array,
    attributes: Array,
    templates: Array,
    gamme: Object,
  },
  data: () => ({
    option: "1",
    cs: 100,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {},
    editedItem: {},
  }),

  computed: {
    variableadd_p() {
      let w = {
        Kind: 55,
        Type: this.$store.state.products_types.map((elm) => elm.id),
        statut_id: 2,
      };
      return w;
    },
    products_cursor() {
      return PRODUCTS_CURSOR;
    },
    attributes_list() {
      let l = [];
      let i = this.templates.findIndex(
        (elm) => elm.id == this.editedItem.template_id
      );
      if (i >= 0)
        l = this.attributes.filter((elm) =>
          this.templates[i].tabtempattributs.includes(elm.id)
        );

      return l;
    },
    gammes_list() {
      let l = [];
      if (this.gammes) l = this.gammes;
      return l;
    },
    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.cs++;
        if (this.editedItem.template_id) this.option = "1";
        if (this.editedItem.gamme_id) this.option = "2";
        this.produit_change();
      } else this.editedItem = Object.assign({}, this.defaultItem);
    }
  },

  methods: {
    customFilter(item, queryText) {
      let textlist = [];
      ["ref", "code", "label"].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    product_change(item, l) {
      let list = [];
      if (l) {
        list = l;
        this.editedItem.produit_id = item;
      }
      let i = list.findIndex((elm) => elm.id == this.editedItem.produit_id);
      if (i >= 0) {
        this.editedItem.product_name = list[i].label;
        this.editedItem.label = list[i].label;
        this.editedItem.product_code = list[i].code;
        this.editedItem.unit_name = list[i].unit;
      }
    },
    produit_change() {
      let i;
      if (this.option == "1") {
        this.editedItem.unit_name = this.unit_name;
        i = this.templates.findIndex(
          (elm) => elm.id == this.editedItem.template_id
        );
        if (i >= 0) {
          this.editedItem.template_name = this.templates[i].label;
        }
      }
      if (this.option == "2") {
        this.editedItem.unit_name = this.unit_name;
        i = this.gammes_list.findIndex(
          (elm) => elm.gamme_id == this.editedItem.gamme_id
        );
        if (i >= 0) {
          this.editedItem.semi_fini = this.gammes_list[i].statut;
        }
      }
    },

    close() {
      this.$emit("close", this.items);
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.progress = true;
        if (this.option == "1") {
          this.editedItem.gamme_id = null;
        } else if (this.option == "2") {
          this.editedItem.template_id = null;
        }

        if (this.editedItem.id > 0) {
          let v = {
            gammebom: {
              id: this.editedItem.id,
              caption: this.editedItem.caption,
              template_id: this.editedItem.template_id,
              gamme_id: this.editedItem.gamme_id,
              produit_id: this.editedItem.produit_id,
              opt: this.editedItem.opt ? 1 : 0,
              dechet: this.editedItem.dechet,
              multiple: this.editedItem.multiple ? 1 : 0,
              write_uid: this.$store.state.me.id,
            },
          };
          await this.maj(UPDATE_GAMME_BOM, v);

          this.progress = false;
          this.items.splice(this.item.index, 1, this.editedItem);
          this.close();
        } else {
          let v = {
            gammebom: {
              fk_gamme: this.item.fk_gamme,
              caption: this.editedItem.caption,
              template_id: this.editedItem.template_id,
              gamme_id: this.editedItem.gamme_id,
              produit_id: this.editedItem.produit_id,
              opt: this.editedItem.opt ? 1 : 0,
              dechet: this.editedItem.dechet,
              multiple: this.editedItem.multiple ? 1 : 0,
              create_uid: this.$store.state.me.id,
              write_uid: this.$store.state.me.id,
            },
          };
          let r = await this.maj(CREATE_GAMME_BOM, v);
          if (r) {
            this.editedItem.id = r.createGammeBom.id;
            this.items.push(this.editedItem);
            this.progress = false;
            this.close();
          }
        }
      }
    },
  },
};
</script>
